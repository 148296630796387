.Dropdown {
    position: absolute;
    background: #ffff;
    max-width: 890px;
    width: auto;
    height: auto;
    margin-top: 170px;
    margin-left: 15em;
    margin-right: 15em;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.dropdown-container {
    margin: 1em 2em;
}

.col-content-container {
    display: flex;
    flex-direction: column;
    margin: 0.8em;
}

.col-color:hover {
    background: #fff5e5;
    cursor: pointer;
}

.col-color a {
    text-decoration: none;
}

.dropdown-titulo{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FF9E00;
    margin-bottom: 0;
}

.dropdown-contenido {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 0;
}

@media (max-width:1200px){
    .Dropdown {
        margin-top: 220px;
    }
}