.home-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 60px;
    text-align: center;
    color: #000000;
    margin-top: 10vh;
}

.icon-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 1em;
}

.icon-subtitle {
    font-weight: 300;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 0;
}

.home-subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #969A9D;
    text-align: center;
}

.btn-home-orange-i {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    background: #FF9E00;
    color: #ffff;
    width: 15rem;
    height: 2.5rem;
    border-radius: 10px;
    margin-top: 2vh;
}

.btn-home-orange {
    font-weight: 700;
    font-size: 17px;
    background: #FF9E00;
    color: #ffff;
    width: 15rem;
    border-radius: 23.5px;
    margin-top: 2vh;
}

.btn-home-orange2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    background: #FF9E00;
    color: #ffff;
    width: 90vw;
    max-width: 386px;
    border-radius: 35.5px;
    margin-top: 2vh;
}

.btn-home-orange:hover {
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
    border: 1px solid #FF9E00
}

.btn-home-orange2:hover {
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
    border: 1px solid #FF9E00
}

.btn-home-orange-i:hover {
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
    border: 1px solid #FF9E00
}

.second-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    margin-top: 20vh;
}

.p-step {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #969A9D;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.p-step-4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #969A9D;
}

.rentabilidad-title {
    font-weight: 500;
    font-size: 43px;
    line-height: 50px;
    text-align: center;
    color: #000000;
    margin-top: 12vh;
}

.rentabilidad-number {
    font-weight: 600;
    font-size: 8vw;
    text-align: center;
    color: #FF9E00;
    margin-top: 6vh;
}

.rentabilidad-number-m {
    font-weight: 600;
    font-size: 16vw;
    text-align: center;
    color: #FF9E00;
    margin-top: 6vh;
}

.projections-container {
    max-width: 255px;
    max-height: 248px;
}

.btn-home-orange3 {
    font-weight: 500;
    font-size: 32px;
    background: #FF9E00;
    color: #ffff;
    width: 90vw;
    max-width: 386px;
    border-radius: 35.5px;
    margin-top: 2vh;
}

.btn-home-orange3:hover {
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
    border: 1px solid #FF9E00
}

.bodegas-container {
    margin-top: 25vh;
}

.dashboard-img-container {
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 75%;
}

.img1-container {
    display: flex;
}

.img2-container {
    display: flex;
    justify-content: end;
    height: auto;
}

.img3-container {
    display: flex;
}

.img1 {
    margin-top: 15vh;
    margin-bottom: 5vh;
}

.img2 {
    align-content: end;
}

.card-img-container {
    position: absolute;
    left: 30%;
    width: 40%;
    height: auto;
}

.servicios-container {
    display: flex;
    flex-direction: row;
    height: auto;
    position: absolute;
    margin-bottom: 90px;
}

.img-home1 {
    border-radius: 56px;
}

.card-title-asesoramiento {
    margin-top: 1em;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}

.container-asesoramiento {
    margin-top: 5em;
}

.card-text-asesoramiento {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    max-width: 400px;
    text-align: justify;
    color: #000000;
    margin: 0 auto;
}

.container-soluciones{
    margin-top: 5em;
}

.img-soluciones {
    border-radius: 55px;
}

.soluciones-title {
    margin-top: 3em;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

@media (max-width:798px){

    .Home {
        margin-right: 1em;
        margin-left:1em;
    }

    .btn-home-orange2{
        width: 231px;
        height: 42.41px;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
    }

    .btn-home-orange3{
        width: 231px;
        height: 42.41px;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
    }

    .home-title{
        font-size: 32px;
        line-height: 50px;
    }

    .home-subtitle {
        font-size: 16px;
        line-height: 30px;
    }

    .second-title {
        font-size: 32px;
        line-height: 50px;
    }

    .dashboard-img-container {
        box-shadow: 1px 1px 8px 2px #dbe9fe;
        border-radius: 12px;
        overflow: auto;
        width: 100%;
    }

    .card-img-container {
        position: absolute;
        left: 10%;
        width: 80%;
        height: auto;
    }

    .img1 {
        margin-top: 60%;
    }

    .img2 {
        margin-top: 35%;
    }

    .img3 {
        margin-top: 70%;
    }

    .home-container {
        padding-bottom: 0;
    }

    .servicios-container {
        flex-direction: column;
        margin: 0;
    }

    .img-templado {
        height: 200px;
        margin-bottom: 2em;
    }
}