main {
    background: white;
    height: 100%;
}

.form-container {
    padding: 7vh 5vw;
    margin: 7vh 26vw;
    background: white;
    border: 0.5px solid #ABAFB3;
    border-radius: 44px;
}

.svc-logo-font {
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FF9E00;
}

.svc-text-font {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #969A9D;
}

.form-footer {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #969A9D;
}

.tc-font{
    color: #FF9E00;
    cursor: pointer;
    text-decoration: none;
}

.logo-container-form {
    cursor: pointer;
}

.btn-form {
    font-weight: 700;
    font-size: 17px;
    background: #FF9E00;
    color: #ffff;
    width: 10rem;
    border-radius: 23.5px;
    margin-top: 2vh;
    align-items: center;
}

.btn-form:hover {
    background: #ffff;
    color: #FF9E00;
    border: 1px solid #FF9E00;
}

.link-element-orange {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #FF9E00;
    margin: auto 1rem;
}

@media (max-width:798px){
    .form-container {
        padding: 7vh 7vw;
        margin: 0;
        background: white;
        border: 0;
    }
    .form-header-desktop {
        display: none;
    }
    .svc-logo-font {
        font-style: italic;
        font-weight: 700;
        font-size: 30px;
        line-height: 32px;
        color: #FF9E00;
    }
}