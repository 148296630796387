.Navbar {
    background: #FFFF;
}

.logo-container {
    max-width: 138px;
    width: auto;
    max-height: 127px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-logo {
    font-style: italic;
    font-weight: 700;
    font-size: 27px;
    text-align: center;
    color: #FF9E00;
    pointer-events: none;
}

.navbar-element {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin: 0em 2em;
}

.menu-container {
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    width: 10em !important;
}

.navbar-dropdown {
    border-right: 1px solid black;
}

.navbar-dropdown:hover {
    cursor: pointer;
}

.active-color {
    color: #FF9E00;
}

.navigate-link:hover {
    cursor: pointer;
}

.navbar-element-orange {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #FF9E00;
}

.navbar-element-orange:hover {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
}

.navbar-element:hover {
    color: #FF9E00;
}

.btn-orange {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    background: #FF9E00;
    color: #ffff;
    width: 8rem;
}

.btn-orange:hover {
    color: #FF9E00;
    text-decoration: underline 1px #FF9E00;
    border: 1px solid #FF9E00;
}

.navbar-toggler-color {
    color: #FF9E00 !important;
    border: 0 !important;
    background: #ffff;
}

.img-navbar {
    object-fit: cover;
}

.brand-container {
    width: 180px;
}

.rb-container {
    display: flex;
    flex-direction: row;
}

.img-templado {
    width: 100%;
    margin-top: 6em;
}

@media (max-width:798px){

    .Navbar {
        margin-top: 0.5em;
    }

    .btn-orange {
    width: 81px;
    height: 24px;
    font-size: 12px;
    }

    .font-logo {
        font-size: 18px;
    }

    .img-navbar {
        width: 170px;
        height: auto;
        margin-left: 15%;
    }

    .btn-solicitar-nav {
        margin: auto;
    }

    .logo-container {
        height: 55px;
        width: 55px;
    }

    .dropdown-menu .navbar-element {
        color: #FF9E00;
    }

}